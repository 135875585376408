
  
  .inputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  
  
  .popupCard {
    min-width: 360px;
    padding: 2rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    border-radius: 4px;
    background: white;
  }
  
  .popupCard > form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .popupCard > form > p {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  .btn-row {
    display: flex;
    flex-direction: row;
  }
  
  .btn-modal {
    border: none;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin: 1rem;
  }
  
  .btn:disabled {
    background-color: rgba(0, 0, 0, 0.4);
    cursor: not-allowed;
  }
  
  .margin-top--small {
    margin-top: 1rem;
    margin-bottom: -1rem;
  }
  
  .margin-top--small > div {
    flex-wrap: wrap;
  }
  
  .margin-top--small > div > div {
    margin-bottom: 1rem;
  }
  
  .margin-top--medium {
    margin-top: 1.5rem;
  }
  
  .margin-top--large {
    margin-top: 4rem;
  }
  
  .error {
    border: 1px solid red !important;
  }
  